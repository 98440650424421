/* InitialLoadingPage.css */

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
  }
  
  .loading-spinner {
    border: 8px solid rgba(213, 189, 189, 0.1);
    border-left-color: var(--primary-text-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  
  .landing-header {
    font-size: 36px;
  }
  
  .landing-description {
    margin-top: 20px;
    font-size: 20px;
  }

  @media(max-width: 748px){
    .landing-header {
        font-size: 30px;
      }
    .landing-description {
        font-size: 18px;
      }
  }

  @media(max-width: 548px){
    .landing-header {
        font-size: 24px;
      }
    .landing-description {
        font-size: 18px;
      }
  }