.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var( --primary-bg-color); 
  padding: 10px 20px;
  color: var( --primary-text-color); 
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navbar-logo h2 {
  margin: 0;
  font-size: 1.4rem;
}

.navbar-logo svg {
  font-size: 30px;
}
.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-user {
  margin-right: 15px;
}

.navbar-user{
  color: var( --primary-text-color); 
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-user a:hover {
  color: #ccc; /* Adjust hover color */
}

button {
  padding: 8px 16px;
  background-color: #007bff; /* Adjust button color */
  color: var( --primary-text-color); 
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3; /* Adjust hover color */
}

@media(max-width : 768px){
    .navbar-logo h2 {
        font-size: 1.2rem;
    }
}

@media(max-width : 548px){
  .navbar-logo h2 {
      font-size: 1rem;
  }

  .navbar-user {
    display: none;
  }
}
