.chat-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
}

.chat-messages-container {
  padding: 1rem;
  margin: 1rem 0rem;
  border-radius: 20px;
  height: calc(100vh - 15rem);
  overflow: scroll;
  background-color: var( --primary-bg-color); 
}

.chat-message-container-right {
  display: flex;
  justify-content: flex-end; 
}

.chat-message-container-right .chat-sender-icon {
  background-color: aqua;
}

.chat-message-container-right .chat-message {
  background-color: antiquewhite;
}

.chat-message {
  display: inline-block;
  border-radius: 20px;
  padding: 9px 18px;
  margin: 8px;
  overflow-wrap: break-word;
  max-width: 80%;
}

.chat-sender-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.chat-sender-time{
  color: var( --secondary-color);
  font-size: 12px;
}
.chat-sender-name{
  color: tomato;
  font-weight: bold;
  font-size: 0.8rem;
}
.chat-message > p{
  margin: 5px 0px;
}

.chat-message-warning{
  background-color: var(--primary-color);
  color: white;
  display: inline-block;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px;
  font-size: 0.9rem;
  word-wrap: break-word;
}

.chat-message-container-left {
  display: flex;
  justify-content: flex-start; /* Align messages to the right by default */
}

.chat-message-container-left .chat-sender-icon {
  background-color: aquamarine;
}
.chat-sender-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  margin: 10px;
}
.chat-message-container-left .chat-message {
  background-color: antiquewhite;
}

.chat-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 20px;
  background-color: var( --primary-bg-color); 
  position: relative;
  bottom: 0;
}

.chat-input {
  padding: 0.6rem;
  margin-right: 10px;
  border-radius: 10px;
  resize: none;
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  flex-grow: 1;
}

.chat-submit-btn {
  padding: 12px;
  border-radius: 0.8rem;
  font-size: 28px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var( --primary-text-color); 
}

.chat-submit-btn:hover {
  background-color: #0056b3;
}



@media(max-width: 748px){
  .chat-submit-btn {
    padding: 10px;
    font-size: 1.5rem;
  }

  .chat-message {
    max-width: 75%;
  }
  
}