

/* Basic styling for the login container */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 7rem);
  background-color: var(--primary-bg-color);
  border-radius: 20px;
  margin: 20px 0px;
  overflow: scroll;
}


.login-input {
  padding: 10px 20px;
  margin-bottom: 20px;
  min-width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 20px;
  font-size: 1rem;
}


.login-btn {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #0056b3;
}

.login-message {
  color: white;
}
.login-container > h1 {
    text-align: center;
    padding: 0px 10px;
  }


@media (max-width: 768px) {
  .login-container > h1 {
    font-size: 1.5rem;
  }

  .login-input {
    min-width: 250px;
  }

}

@media (max-width: 600px) {
  .login-container > h1 {
    font-size: 1.3rem;
  }

  .login-input {
    width: 225px;
  }
}
