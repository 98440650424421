.sidebar-container {
  background-color: #333;
  overflow: scroll;
  color: var( --primary-text-color); 
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.text-center {
  text-align: center; 
}

.sidebar-user-details {
  margin-top: 20px;
}

.sidebar-user {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 0px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 0.9rem;
  word-wrap: break-word;
  cursor: pointer; 
  transition: background-color 0.3s ease; 
}

.sidebar-user:hover {
  background-color: #e0e0e0; 
  color: black;
}

.sidebar-user svg {
  margin-right: 8px;
  border-radius: 50%;
  padding: 6px;
  border: 2px dotted  var( --primary-text-color); 
}

.sidebar-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 15px 0px 20px;
    font-size: 0.8rem;
}

.sidebar-header svg{
    margin-top: 5px;
    font-size: 24px;
}

.sidebar-header svg:hover{
    margin-top: 5px;
    font-size: 26px;
    transition: all 0.3s ease;
}

.sidebar-header-title{
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar-badge{
  background-color: green;
  padding: 5px 10px;
  border-radius: 20px;
  z-index: 9999;
  font-size: 0.7rem;
}
