:root {
  --primary-bg-color: #333;
  --secondary-bg-color: #fff; 
  --primary-color: #007bff; 
  --secondary-color: #a3abb2;
  --accent-color: #28a745; 
  --text-color: #333;
  --primary-text-color: #fff; 
}
html {
  overflow: scroll;
}
body {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}
::-webkit-scrollbar {
  width: 0;  
  height: 0;
  background: transparent;  
}

::-webkit-scrollbar-thumb {
  width: 0;  
  height: 0;
  background: transparent;
}


.chat-app{
  height: 100vh;
  overflow-y: hidden;

}
.chatapp-container {
  display: flex;
  gap: 20px;
}
.sidebar-container {
  width: 250px;
  position: fixed;
  height: 96vh;
  top: 0;
  left: 0;
  margin: 15px;
  overflow: hidden;

}
.chatapp-body-container {
  width: 100%;
  padding: 15px;
  margin-left: 270px;
  height: 98vh;

}

.chatapp-body-container-full {
  width: 100%;
  padding: 15px;
  margin-left: 0px;
  height: 100vh;
  transition: all 0.3s ease;
  overflow: hidden;
}

.text-center {
  text-align: center;
}

@media(max-width: 768px){
  body{
    font-size: 0.9rem;
  }
  .sidebar-container {
    width: 94%;
    z-index: 999;
    transition: all 0.3s ease;
  }
  .chatapp-body-container {
    display: none;
  }

}

@media(max-width: 480px){
  body{
    font-size: 13px;
  }
  .sidebar-container {
    width: 90%;
  }
}
